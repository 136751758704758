<template>
  <div id="app">
    <!-- <myHome></myHome> -->
    <router-view />
  </div>
</template>

<script>
import './view/buding.js'
// import myHome from '@/components/home.vue'

export default {
  name: 'App',
  components: {
    // myHome,
  },
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
</style>
