import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
// 引入样式
import 'vue-easytable/libs/theme-default/index.css'
import { VeLocale } from 'vue-easytable'
// 引入中文语言包
import zhCn from 'vue-easytable/libs/locale/lang/zh-CN.js'

VeLocale.use(zhCn)
// 导入样式
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import './index.css'
// import './element-variable.scss'
import VueEasytable from 'vue-easytable'

import './view/buding.js'

// 使用axios
Vue.prototype.axios = axios
Vue.use(VueEasytable)
Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

// hqchart 插件修改了源代码的umychart.js文件夹下的郑州商品交易所，添加了对二甲苯和烧碱(全局搜索.CZCE)
