import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../components/home(one).vue'

Vue.use(VueRouter)

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

const routes = [
  { path: '', redirect: '/login' },
  { path: '/', redirect: '/login' },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/view/login.vue'),
    // hidden: true,
  },
  {
    path: '/home',
    name: 'myHome',
    component: () => import('@/view/home/home.vue'),
    // component: HomeView,
    // hidden: true,
  },
]

// const RouterConfig = {
//   mode: 'history', // require service support
//   scrollBehavior: () => ({
//     y: 0,
//   }),
//   routes,
// }

// export const router = new Router(RouterConfig)
// const createRouter = () => new VueRouter(RouterConfig)

// const router = createRouter()

export const router = new VueRouter({
  routes,
})
// 路由守卫

router.beforeEach(function (to, from, next) {
  const user = localStorage.getItem('user')
  if (user) {
    return next()
  } else {
    if (to.path == '/login' || to.path == '/' || to.path == '') {
      return next()
    } else {
      next('/login')
    }
  }
})
export default router
